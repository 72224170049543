import React, { Component } from 'react';
import { Tabs } from 'antd';
import Footer from '../../src/components/Footer';
import './Contact.css';
const TabPane = Tabs.TabPane;
class Contact extends Component {
    constructor(props) {
        super(props);
        this.state = {         
            tabsKey:0,                  
        };
    } 
    changgAbouts(key){     
        this.setState({tabsKey:key-1})
    }  
   
    render() {
        const { tabsKey } = this.state;
        return (            
            <div className="homepage">
                <div className="common-banner contact-banner">
                   <div className="container">
                      <div className="banner-title-line"></div>
                      <h2>关于我们</h2>
                   </div>
                </div>
                <div className="about-box layout common-abouts-nav">
                    <div className="container">
                        <div className="about-content">
                            <div className="about-nav">
                                <ul>
                                    {
                                        ['关于我们',].map((item,index)=>(
                                            <li key={index} className={tabsKey === index ? 'active':' '}  
                                            onClick={(e)=>this.setState({tabsKey:index})}>{item}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                            <Tabs defaultActiveKey="1" onChange={(key) => this.changgAbouts(key)}>
                                <TabPane tab="关于我们" key="1">                               
                                </TabPane>
                               
                            </Tabs>
                            <div className="about-right">
                              <div className="about-right-box">
                              {
                                  tabsKey === 0 ?                             
                                  <div className="about-right-sort scrollbar">
                                    <h2 className="about-describe"><span>中国报道杂志社机构设置</span></h2>
                                    <span className="red-line"></span>
                                    <div className="about-summary contact-summary">                                        
                                        <p>《中国报道》创刊于1950年，是由中共中央宣传部领导，国务院新闻办、中央直属对外宣传机构中国外文局主管的国家级时政财经类中文月刊，被中共中央宣传部列为政经类重点期刊，也是中央数字化转型示范单位。其高端权威的报道在政府和大中型企业颇具影响力，优质品牌获准进入党代会、全国“两会”中央经济工作会议等重大会议新闻中心及代表驻地。发行网络覆盖中央各大部委和地方政府、知名企业;美、英、法、俄等136个驻华使馆、领馆;进驻北京京西宾馆、钓鱼台国宾馆、国家会议中心等国事、外事及重大活动的重要场所;一线城市五星级酒店宾馆、重要商务场所及机场候机楼，并通过国际邮局和中国国际图书贸易总公司开展海外发行。</p>
                                        <p>中国报道中文网<a href="http://www.chinareports.org.cn" target="_blank">www.chinareports.org.cn</a>（以下简称中国报道网）是中共中央直属、国务院新 闻办所属对外宣传机构——中国外文出版发行事业局（中国国际出 版集团）主管，中国报道社主办的刊网同步的综合信息平台</p>                                       
                                        <p>中国报道网是《中国报道》杂志内容丰富、延伸和拓展的传播平台， 作为中国报道社全媒体发展战略的重要组成部分，具有时效性强， 覆盖范围广，表现形式多样化等优势。中国报道网密切关注中国改 革开放的发展进程，权威解读经济政策，发挥媒体舆论导向作用，及 时报道并深入剖析热点经济事件和现象，以全球视角透视中国经济 领域的重大举措和瞩目人物，提供国内外权威经济观点和主流价值 观，竭诚为中国和世界各国的访问者提供迅捷、权威的信息服务。</p>
                                        <p>中国报道网立足北京，站在国家外宣角度对栏目进行整体布局，涵 盖时政、金融、创投、民生、文化、旅游、科教、医疗、食品、政法、艺 术、人物、汽车、房产、园区、产经、企业、地方经济等<span className="about-black">30</span>多个领域。 中国报道网每天访问人次超过<span className="about-black">10万</span>，<span className="about-black">google PR</span>值<span className="about-black">6</span>，得到众多领域专家和地方政府的高度评价。</p>
                                    </div>
                                  </div> :null
                               }
                              </div>
                            </div>
                        </div>                        
                    </div>                   
                </div>               
                <Footer/>
            </div>
           
        );
    };
    componentDidMount () {        
      }

}
export default (Contact);
