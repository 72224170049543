import React, { Component } from 'react';
import './Header.css';
import { Link, withRouter } from "react-router-dom";
import { connect } from 'react-redux';

const mapStateToProps = state => ({
    webUrl:state.common.webUrl,
  
});

class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {         
            phoneState:false,                   
        };
    }
    showPhoneMenu(e){
       this.setState({phoneState:true});
    }
    closePhonNav(){
        this.setState({phoneState:false});
    }
    render() {
        const { location } = this.props;
        let pathname = location.pathname;
        const { phoneState,} = this.state;
        return (
            <ul className={"app-menu " +(phoneState  ? 'phone' : '')}>
                <li className={`menu-logo`}>
                    <Link to="/"></Link>
                </li>
                <li className="phone-logo">
                    <div>
                        <Link to="/"></Link>
                        <img src="/image/phone/phone-nav-close.svg" alt="" onClick={(e)=>this.closePhonNav(e)}></img>
                    </div>
                </li>
                <li className="menu-space"></li>
                <li className={pathname === '/about' ? 'active' : ''}>
                    <Link to="/about">关于大赛</Link>
                </li>
                <li className={pathname === '/news' || pathname === '/newsDetails' ? 'active' : ''}>
                    <Link to="/news">大赛新闻</Link>
                </li>
                <li className={pathname === '/updates' ? 'active' : ''}>
                    <Link to="/updates">赛区动态</Link>
                </li>
                <li className={pathname === '/works' ||  pathname === '/worksDetails'? 'active' : ''}>
                    <Link to="/works">参赛作品</Link>
                </li>
                {/* <li className={pathname === '/awards' || pathname === '/awardsDetails' ? 'active' : ''}>
                    <Link to="/awards">获奖公示</Link>
                </li>
                <li className={pathname === '/review' ? 'active' : ''}>
                    <Link to="/review">往期回顾</Link>
                </li> */}
                <li className={pathname === '/contact' ? 'active' : ''}>
                    <Link to="/contact">关于我们</Link>
                </li>
                <li>
                    <a href={this.props.webUrl} target="_blank">登录</a>
                </li>                
                <li className="phone-nav-icon" onClick={(e)=>this.showPhoneMenu(e)}>                   
                </li>               
            </ul>                       
        );
    };
    componentWillReceiveProps(nextProps){
         if (nextProps.location.pathname != this.props.location.pathname) {
            this.setState({phoneState:false});  
            window.scrollTo(0, 0)          
         }
    }
}

export default connect(
    mapStateToProps
   )(withRouter(Header));
