import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Footer from '../../src/components/Footer';
import data from '../../src/datas/data';
import { GetQueryString } from '../../src/ADS';
import './AwardsDetails.css';

class AwardsDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {         
            tabsKey:0,          
            index:0,
        };
    } 
   
    render() {
        const { tabsKey,index } = this.state; 
        console.log('awardsArr',data['awards_'+tabsKey][0].content[index]);      
        return (
            <div className="homepage">
                <div className="common-banner awards-banner">
                   <div className="container">
                      <div className="banner-title-line"></div>
                      <h2>获奖公示</h2>
                   </div>
                </div>
                <div className="about-box layout awards-details">
                    <div className="container">
                        <div className="about-content">                            
                            <div className="about-right">
                              <div className="about-right-box">
                                <div className="go-back">
                                <Link to="/awards"><img src="image/icon/go-back.svg" alt=""></img>返回上一级</Link>
                                </div>
                                  <h2>{data['awards_'+tabsKey][0].awards}</h2>                                       
                                       <div className="awards-pic">
                                           <h3>{index === 0 ? '一等奖':(index === 2 ? '三等奖' :'二等奖')}</h3> 
                                           <span className="red-line"></span> 
                                          <div className="works-details-top-left">
                                                <h2>{data['awards_'+tabsKey][0].content[index].title}
                                                    <img src="image/icon/hot-icon.svg" alt="" style={{marginLeft:'5px'}}></img></h2>
                                                <div className="works-left-tip">
                                                    <div className="works-top-sorts">
                                                        <span>{data['awards_'+tabsKey][0].content[index].name}</span>                                   
                                                        <span>{data['awards_'+tabsKey][0].content[index].area}</span>
                                                    </div>
                                                    <div className="works-options">                                       
                                                        <div className="works-opertion">
                                                            <span><img src="image/icon/read-icon.svg" alt=""></img>{data['awards_'+tabsKey][0].content[index].read}</span>
                                                            <span><img src="image/icon/zan-icon.svg" alt=""></img>{data['awards_'+tabsKey][0].content[index].like}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="awards-pic-describe">
                                                   <p>
                                                   {data['awards_'+tabsKey][0].content[index].describe ? data['awards_'+tabsKey][0].content[index].describe : ''}</p>
                                                </div>
                                            </div>                                
                                            <div className="awards-only first">                                                
                                                <ul>
                                                    {
                                                        data['awards_'+tabsKey][0].content[index].img.map((item,index)=>(
                                                            <li key={index}><img src={item} alt=""></img></li>                                                            
                                                        ))
                                                    }                                                   
                                                </ul>                                               
                                            </div>
                                                                       
                                      </div>                                                                       
                              </div>
                            </div>
                        </div>                        
                    </div>                   
                </div> 
                <Footer/>   
            </div>
        );
    };
    componentDidMount() {             
        let num = GetQueryString("index");
        let type = GetQueryString("type");
        this.setState({tabsKey:type,index:num}); 
    
    }
}

export default AwardsDetails;