import React, { Component } from 'react';
import { Tabs } from 'antd';
import Footer from '../../src/components/Footer';
import data from '../../src/datas/data';
import './Awards.css';
const TabPane = Tabs.TabPane;
class Awards extends Component {
    constructor(props) {
        super(props);
        this.state = {         
            tabsKey:0, 
        };
    } 
    getAwardsDetails(e,index,type){   
       this.props.history.push('/awardsDetails?index='+index +'&type='+type);  
    }
    changgAbouts(key){  
        this.setState({tabsKey:key-1})
    }
    render() {
        const { tabsKey, } = this.state; 
        console.log('awardsArr',data['awards_'+tabsKey][0].content[1].cover,);
        return (
            <div className="homepage">
                <div className="common-banner awards-banner">
                   <div className="container">
                      <div className="banner-title-line"></div>
                      <h2>获奖公示</h2>
                   </div>
                </div>
                <div className="about-box layout awards">
                    <div className="container">
                        <div className="about-content">
                            <div className="about-nav">
                                <ul>
                                    {
                                        ['城市印象奖','乡村振兴奖','美好生活奖','大美中国奖','新时代的奋斗者奖',].map((item,index)=>(
                                            <li key={index} className={tabsKey === index ? 'active':' '}  
                                            onClick={(e)=>this.setState({tabsKey:index})}>{item}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                            <Tabs defaultActiveKey="1" onChange={(key) => this.changgAbouts(key)}>                                
                                {
                                   ['城市印象奖','乡村振兴奖','美好生活奖','大美中国奖','新时代的奋斗者奖',].map((item,index)=>(
                                    <TabPane tab={item} key={index + 1}>                               
                                    </TabPane>
                                    ))  
                                }                               
                            </Tabs>
                            <div className="about-right">
                              <div className="about-right-box">
                                  <h2>{data['awards_'+tabsKey][0].awards}</h2> 
                                  {
                                    [1,2,3].map((item,index)=>(
                                        (data['awards_'+tabsKey][0].content[index].cover ? 
                                        <div className="awards-pic" key={index}>                                 
                                            <div className="awards-only first">
                                                <h3>{index === 0 ? '一等奖':(index === 2 ? '三等奖' :'二等奖')}</h3> 
                                                <span className="red-line"></span>
                                                <div className="awards-pic-cover" onClick={(e)=>this.getAwardsDetails(e,index,tabsKey)}>
                                                    <img src={data['awards_'+tabsKey][0].content[index].cover} alt=""></img>
                                                    <span className="totalnum">{data['awards_'+tabsKey][0].content[index].img.length}</span>
                                                </div>                                
                                            </div>
                                            <div className="works-details-top-left">
                                                <h2>{data['awards_'+tabsKey][0].content[index].title}
                                                    <img src="image/icon/hot-icon.svg" alt="" style={{marginLeft:'5px'}}></img></h2>
                                                <div className="works-left-tip">
                                                    <div className="works-top-sorts">
                                                        <span>{data['awards_'+tabsKey][0].content[index].name}</span>                                   
                                                        <span>{data['awards_'+tabsKey][0].content[index].area}</span>
                                                    </div>
                                                    <div className="works-options">                                       
                                                        <div className="works-opertion">
                                                            <span><img src="image/icon/read-icon.svg" alt=""></img>{data['awards_'+tabsKey][0].content[index].read}</span>
                                                            <span><img src="image/icon/zan-icon.svg" alt=""></img>{data['awards_'+tabsKey][0].content[index].like}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                           
                                      </div> :null
                                        )
                                    
                                    )) 
                                  }                                                                                               
                              </div>
                            </div>
                        </div>                        
                    </div>                   
                </div> 
                <Footer />
            </div>
        );
    };
}

export default Awards;