import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tabs } from 'antd';
import { isNumber,getImg } from '../../src/ADS';
import Footer from '../../src/components/Footer';
import './News.css';
import moment from 'moment';
import {  getNews,getStoryDetail } from '../actions/app';
const TabPane = Tabs.TabPane;
const mapStateToProps = state => ({   
    news: state.home.news,
    newsTotalNumber:state.home.newsTotalNumber,
    isNewsData:state.home.isNewsData
});
// const newsImg = [{"img":"image/news04.png"},{"img":"image/news05.png"},{"img":"image/news06.png"}];
class News extends Component {
    constructor(props) {
        super(props);
        this.state = {         
            tabsKey:0,
            page:1,
            limit:4,
            newKey:'589775513', 
            starKey:'93088',   
            seriesKey:'602264202',                              
        };
    }   
    getNewsDetails(e,_key){
      this.props.getStoryDetail(_key);
      this.props.history.push('/newsDetails?key='+_key);     
    } 
    changgAbouts(key){  
        this.setState({tabsKey:key-1})
    }
    // 分页
    handleClickPageAjax(e,action,limit,page,pageCount,pagename){  
        const {starKey,seriesKey} = this.state;      
        const { getNews } = this.props;       
        switch(action){
            case 'first':
              if(page === 1){
                return false;
              }
              page=1
              break;
            case 'prev':
            console.log('page',page);
              if(page <= 1){
                page=1;
                return false;
              }else{
                page--;
              }
              break;
            case 'next':
              if(page >= pageCount){
                page=pageCount;
                return false;
              }else{
                page++;
              }
              break;
            case 'last':
              if(page === pageCount){
                return false;
              }
              page=pageCount
              break;
          }
          this.setState({[pagename]:page});        
          getNews(starKey,seriesKey, page, limit);
    }
    handleChangePageAjax_1(e,pageCount,pagename){
        let page=e.target.value;
        if(isNumber(page)){
          if(page <= 1){
            page=1;
          }else if(page >= pageCount){
            page=pageCount;
          }
        }else{
          page=1;
        }
        this.setState({[pagename]:page});
  }
  //高级检索-分页-enter请求分页
   handleSendPageAjax_1(e,limit){
    const {starKey,seriesKey,page} = this.state; 
    console.log('page',page);
    if(e.keyCode === 13){
        this.props.getNews(starKey,seriesKey,page,limit);
      }
   }
    render() {
        const { tabsKey,page,limit } = this.state;
        const { news,newsTotalNumber,isNewsData } = this.props;
        let newspageCount = Math.ceil(newsTotalNumber/limit);
        console.log('news',news);
        return (
            <div className="homepage">
                <div className="common-banner news-banner">
                   <div className="container">
                      <div className="banner-title-line"></div>
                      <h2>大赛新闻</h2>
                   </div>
                </div>
                <div className="about-box layout">
                    <div className="container">
                        <div className="about-content">
                            <div className="about-nav">
                                <ul>
                                    {
                                        ['全国大赛新闻'].map((item,index)=>(
                                            <li key={index} className={tabsKey === index ? 'active':' '}  
                                            onClick={(e)=>this.setState({tabsKey:index})}>{item}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                            <Tabs defaultActiveKey="1" onChange={(key) => this.changgAbouts(key)}>
                                <TabPane tab="全国大赛新闻" key="1">                               
                                </TabPane>                                
                            </Tabs>
                            <div className="about-right">
                              <div className="about-right-box News-right-box home-news-left">
                              {
                                news && news.length ?                             
                                <ul>
                                        {
                                            news && news.map((item, index) => (                                        
                                            <li key={index} onClick={(e)=>this.getNewsDetails(e,item._key)}>                                                
                                                {
                                                  item.cover ? 
                                                  <div className="news-left-img" 
                                                       style={{backgroundImage:'url('+item.cover+'?imageView2/2/w/250'+')'}}></div>:
                                                  <div className="news-left-img" 
                                                       style={{backgroundImage:'url(./image/news-common.png)',backgroundPosition:'center'}}></div> 
                                                 }                                             
                                                <div className="news-left-content">
                                                    <h4 style={{WebkitBoxOrient: "vertical"}}>{item.title}</h4>                                                   
                                                    <p style={{WebkitBoxOrient:'vertical'}}>{item.memo ? item.memo :''}</p>                                                     
                                                    <div className="news-footer">
                                                        <span>大赛新闻</span>
                                                        <span>{moment(item.time).format('YYYY-MM-DD HH:mm')}</span>
                                                    </div>                                                
                                                </div>
                                            </li>
                                        ))}                                   
                                </ul> :null
                              }
                              {
                                isNewsData ? <div className="noData" style={{padding:'100px 0'}}>
                                     <img src="image/no-data.png" alt=""></img>
                                     <p>没有找到符合条件的结果</p>
                                  </div> :null
                              }                                                               
                              </div>
                              {/* 分页 */}
                              {
                                newspageCount > 1  ?                             
                                <div className="pagebox">
                                    <span className="pageleft">
                                        <span className="pagefirst" 
                                           style={{color:(page === 1 ? '#b2b2b2' : ''),cursor:(page === 1 ? 'default' : 'pointer')}} 
                                           onClick={(e)=>this.handleClickPageAjax(e,'first',limit,page,newspageCount,'page')}>首页</span>
                                        <span className="pageprev" 
                                           style={{color:(page === 1 ? '#b2b2b2' : ''),cursor:(page === 1 ? 'default' : 'pointer')}}
                                           onClick={(e)=>this.handleClickPageAjax(e,'prev',limit,page,newspageCount,'page')}>上一页</span>
                                    </span>
                                    <span className="pagemid">
                                        当前第<input type="text" className="pageNumInput" value={page}
                                        onChange={(e)=>this.handleChangePageAjax_1(e,newspageCount,'page')} 
                                        onKeyUp={(e)=>this.handleSendPageAjax_1(e,limit)}/>页，共<em>{newspageCount}</em>页，<em>{newsTotalNumber}</em>条搜索结果
                                    </span>
                                    <span className="pageright">
                                        <span className="pagenext" 
                                           style={{color:(page === newspageCount ? '#b2b2b2' : ''),cursor:(page === 1 ? 'default' : 'pointer')}}
                                           onClick={(e)=>this.handleClickPageAjax(e,'next',limit,page,newspageCount,'page')}>下一页</span>
                                        <span className="pagelast" 
                                           style={{color:(page === newspageCount ? '#b2b2b2' : ''),cursor:(page === 1 ? 'default' : 'pointer')}}
                                           onClick={(e)=>this.handleClickPageAjax(e,'last',limit,page,newspageCount,'page')}>末页</span>
                                    </span>
                                </div> :null
                                }
                            </div>
                        </div>                        
                    </div>                   
                </div> 
                <Footer/>   
            </div>
        );
    };
    componentDidMount() {   
        const {limit,page , starKey,seriesKey,} = this.state;     
        const { getNews, } = this.props;
        getNews(starKey,seriesKey, page, limit);
    
    }
}
export default connect(
    mapStateToProps,
    {  getNews,getStoryDetail },
)(News);
