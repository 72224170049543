const awardsArr={    
    "awards_0":[     
        {    
            "awards":"城市印象奖", 
             "content":{
                "0":{
                    "cover":"image/awards-img1.png",
                    "img":["image/awards-img1.png","image/awards-img2.png"],
                    "name":"张三",
                    "area":"青岛",
                    "like":"200",
                    "read":"10",
                    "title":"祖国的花朵",
                    "describe":'风景如画',
                },                
                "1":{
                    "cover":"image/awards-img2.png",
                    "img":["image/awards-img2.png","image/awards-img1.png"],
                    "name":"吉栗栗",
                    "area":"青岛",
                    "like":"105",
                    "read":"16",
                    "title":"我亲爱的祖国",
                    "describe":'描述',
                },
                "2":{
                    "cover":"image/awards-img1.png",
                    "img":["image/awards-img2.png","image/awards-img1.png","image/awards-img2.png"],
                    "name":"刘洋",
                    "area":"广东省",
                    "like":"95",
                    "read":"2015",
                    "title":"小心思",
                    "describe":'描述',
                },
                
             },
        }, 
    ],
    "awards_1":[     
        {    
            "awards":"乡村振兴奖", 
            "content":{
                "0":{
                    "cover":"image/awards-img2.png",
                    "img":["image/awards-img1.png","image/awards-img1.png","image/awards-img1.png"],
                    "name":"吉栗栗",
                    "area":"青岛",
                    "like":"105",
                    "read":"16",
                    "title":"我亲爱的祖国" ,
                    "describe":'描述',                   
                },                
                "1":{
                    "cover":"image/awards-img2.png",
                    "img":["image/awards-img2.png","image/awards-img2.png","image/awards-img1.png",],
                    "name":"刘洋",
                    "area":"广东省",
                    "like":"95",
                    "read":"2015",
                    "title":"小心思",
                    "describe":'描述', 
                },
                "2":{
                    "cover":"image/awards-img1.png",
                    "img":["image/awards-img2.png"],
                    "name":"张三",
                    "area":"青岛",
                    "like":"200",
                    "read":"10",
                    "title":"祖国的花朵" ,
                    "describe":'描述',                    
                },                
             }, 
        }, 
    ],
    "awards_2":[     
        {      
            "awards":"美好生活奖",  
            "content":{
                "0":{
                    "cover":"image/awards-img1.png",
                    "img":["image/awards-img1.png","image/awards-img2.png","image/awards-img1.png"],
                    "name":"刘洋",
                    "area":"广东省",
                    "like":"95",
                    "read":"2015",
                    "title":"小心思",
                    "describe":'美好生活',                                        
                },                
                "1":{
                    "cover":"image/awards-img1.png",
                    "img":["image/awards-img2.png",],
                    "name":"吉栗栗",
                    "area":"青岛",
                    "like":"105",
                    "read":"16",
                    "title":"我亲爱的祖国" ,
                    "describe":'描述',
                },
                "2":{
                    "cover":"image/awards-img2.png",
                    "img":["image/awards-img2.png"],
                    "name":"张三",
                    "area":"青岛",
                    "like":"200",
                    "read":"10",
                    "title":"祖国的花朵",
                    "describe":'描述',                    
                },                
             }, 
        }, 
    ],
    "awards_3":[     
        {     
            "awards":"大美中国奖", 
            "content":{
                "0":{
                    "cover":"image/awards-img2.png",
                    "img":["image/awards-img1.png"],
                    "name":"吉栗栗",
                    "area":"青岛",
                    "like":"105",
                    "read":"16",
                    "title":"我亲爱的祖国" ,
                    "describe":'描述',                   
                },                
                "1":{
                    "cover":"image/awards-img1.png",
                    "img":["image/awards-img1.png","image/awards-img2.png","image/awards-img1.png",],
                    "name":"刘洋",
                    "area":"广东省",
                    "like":"95",
                    "read":"2015",
                    "title":"小心思",
                    "describe":'描述',
                },
                "2":{
                    "cover":"image/awards-img1.png",
                    "img":["image/awards-img2.png"],
                    "name":"张三",
                    "area":"青岛",
                    "like":"200",
                    "read":"10",
                    "title":"祖国的花朵" ,
                    "describe":'描述',                   
                },                
             },
        }, 
    ],
    "awards_4":[     
        {     
            "awards":"新时代的奋斗者奖",  
            "content":{
                "0":{
                    "cover":"image/awards-img1.png",
                    "img":["image/awards-img1.png","image/awards-img2.png"],
                    "name":"高阳",
                    "area":"青岛",
                    "like":"105",
                    "read":"16",
                    "title":"我亲爱的祖国啊" ,
                    "describe":'描述',                   
                },                
                "1":{
                    "cover":"image/awards-img2.png",
                    "img":["image/awards-img2.png","image/awards-img1.png"],
                    "name":"刘洋",
                    "area":"广东省",
                    "like":"95",
                    "read":"2015",
                    "title":"画中画",
                    "describe":'描述',
                },
                "2":{
                    "cover":"image/awards-img1.png",
                    "img":["image/awards-img1.png,image/awards-img2.png"],
                    "name":"李四",
                    "area":"大连",
                    "like":"200",
                    "read":"10",
                    "title":"祖国的花朵",
                    "describe":'描述',                    
                },                
             },
        }, 
    ],
}
export default awardsArr