import React, { Component } from 'react';
import { Tabs } from 'antd';
import Footer from '../../src/components/Footer';
import './About.css';
const TabPane = Tabs.TabPane;
const aboutDetails=[
    {"title":"中国报道“祖国，你好”主题摄影大赛征集活动","details":"为迎接新中国成立70周年，全方位、多角度展现新中国成立以来，特别是改革开放40年来中国在各方面取得的辉煌成就，中国报道“祖国，你好”主题摄影大赛拉开帷幕。大赛以“个人”与“国家”的关系为视角，用摄影作品生动展现新中国成立70周年以来取得的辉煌成就，祖国的壮美山河以及中国人民追求幸福生活、努力奋斗的精神风貌。 本次活动由中国外文局指导，中国报道杂志社主办，中国报道网承办。作品征集时间：2019年4月1日——2019年10月1日。参赛对象面向中国报道广大读者、通讯员、社会各界的摄影家及摄影爱好者等。 本次摄影活动分设为：城市印象、乡村掠影、美好生活、美景中国、新时代的奋斗者，共5个组别。每组分别评出一、二、三等奖，获奖者除获得大赛证书外，还将获得500-5000元奖金不等。本次摄影大赛同时设置摄影基地评选活动。 大赛按照省级区域设置分赛区，由分赛区组委会组织参赛者，按照要求和时间将作品报送至中国报道杂志社。入选作品将刊登于中国报道网(www.chinareports.org.cn)“祖国，你好”主题摄影大赛专题网页。大赛组委会将邀请专家评委从所有初选入围的作品中评选出各组别一、二、三等奖。所有获奖作品将分别发表在《中国报道》杂志、官方网站及官方微信公众号。 2019年底前，活动组委会还将举办“祖国，你好”主题摄影作品东盟展(泰国曼谷)，将入选优秀作品进行展示。同时，选取城市印象组摄影作品，在泰国举办“中国城市印象摄影展”，以此推广中国旅游文化，推动中国城市走出去。 "},
    {"title":"报名须知","details":"(一)申报作品要求以“祖国，你好”为主题，按照城市印象、乡村掠影、美好生活、美景中国、新时代的奋斗者5个类别投稿，要求主题鲜明，主要技术要求参照评审标准。 (二)作品提交要求： 1、作品需注明作者简介和作品简介，包括联系方式、作品标题、拍摄时间、地点等，并附简要图片说明。 2、投稿电子文件须为jpg格式，大小不低于1024*768，清晰度不低于72DPI，组图不超过5张，拍摄时间、题材、形式、风格不限，彩色黑白均可。(不接受邮寄作品光盘) 3、申报作品须为投稿者本人原创，无版权争议。所涉及的名誉权、肖像权、著作权等法律责任均由作品本人负责。作品可作适当的后期处理，但不得做过多合成。 (三)凡与本次摄影大赛主题不符、思想内容不健康、资料不全等不符合征集要求的作品，不得参加评选。 (四)中国报道杂志社对所有获奖作品有权调取用于输出制作的大尺寸文件，有权在相关非营利性展览、印刷、出版等活动中使用，不再另付稿酬。 (五)本次活动不收取报名费，所有参赛作品均不退回。 (六)本次活动的解释权归主办单位，参加本次活动即视为同意并遵守本次活动上述各项该规则。 (七)投稿方式：本次大赛采取网络投稿的方式，参评者首先需要登陆“中国报道网”(www.chinareports.org.cn)，进入“‘祖国，你好’主题摄影大赛”专题页进行注册，并按要求填写真实的个人信息。注册之后按照规定程序上传参评作品。"},
    {"title":"参赛流程","details":"（一）分展区组建 2019年3月1日前，确定各分展区承办单位。 （二）作品征集 由分展区组委会组织参展者，按照要求和时间将作品报送至中国报道社。 （三）初选入围 由各分展区评审组专家评选优秀入选作品，每月不少于10幅（组）。 入选作品将刊登于中国报道网（www.chinareports.org.cn）“祖国，你好”主题摄影大赛专题网页。 （四）终评颁奖 1、9月15日前，大赛组委会将邀请专家评委从所有初选入围的作品中评选出各组别一、二、三等奖。所有获奖作品将分别发表在《中国报道》杂志、官方网站及官方微信公众号。 2、9月15日前，大赛及分展区组委会分别评选出入围城市、乡镇及景区。 3、9月15日前，大赛组委会评选出最佳分展区组织奖。 4、9月15日，获奖个人和单位，来中国外文局参加颁奖典礼。 （五）后续活动  2019年底前，举办“祖国，你好”主题摄影作品东盟展（泰国曼谷），将入选优秀作品进行展示。同时，选取比展中表现突出，有合作意向的城市，在泰国举办“中国城市印象摄影展”，以此推广中国旅游文化，推动中国城市走出去。"}
]
const homeJudges = [
    {"name":'李舸',"img":"image/judges2.png","info":"李舸，中国摄影家协会主席，人民日报总编室部务委员、高级记者。主要负责党和国家重大时政新闻的采访工作。 曾获中国摄影金像奖、中国新闻奖、中国新闻摄影记者金眼奖、奥运会体育摄影作品金质收藏大奖等。 被授予“全国五一劳动奖章”、“全国德艺双馨文艺工作者”、“中央国家机关优秀专业技术能手”、“全国十佳青年摄影记者”、“全国十大时装摄影师”和人民日报“十大杰出青年”等称号。 将摄影、绘画、书法、篆刻等多种艺术形式与传统手工技艺巧妙融合，形成了摄影题材独家、书法创作独特、作品材质独创、原作版数独幅的新型跨界艺术作品。 从影二十多年来，先后在亚、美、欧、澳、非等洲的众多国家举办摄影艺术展，拍摄大型画册。"},  
    {"name":'尹杰',"img":"image/judges1.png","info":"尹杰，中国报道杂志社副总编辑，《INTERNI设计时代》执行出版人。清华大学工学、文学双学士。1996年参加工作，担任《人民画报》中文版编辑。 2003年-2011年供职于中国国家地理，历任总编辑助理、制作总监等职。2011年加入中国报道杂志社，自2015年引进欧洲权威设计媒体版权，创办运营《INTERNI设计时代》。先后负责策划执行了《与摄影同行——吴寅伯纪念展》（北京）、《传承与创新——中国设计四十年》（巴黎）、《千亿像素看中国——城市与人》（罗马）等大型国内外摄影、设计、艺术展览。"}, 
    {"name":'曾星明',"img":"image/judges4.png","info":"曾星明，1991年毕业于北京大学中国语言文学系，获文学学士学位。 现为《中国摄影家》杂志运营总监，兼任中国记协理事、中国摄影家协会理事、中国摄影著作权协会理事。一直以来，从事摄影媒体运营及摄影研究、评论。策划操作过中国纪实摄影展、中国当代摄影新锐展、中国创意摄影展、中国新画意摄影展等品牌项目。撰写发表了《我们如何报道》《故乡离我远去》《长城的守望者》等文章。主编出版了《生活·纪事》、《人像·凝视》、《风景·观看》、《新风景摄影》、《世俗生活风雅颂》等图书。"},
    {"name":'刘嵘',"img":"image/judges3.png","info":"中国报道杂志图片总监，北京电影学院摄影系毕业，23年新闻摄影和图片编辑工作经验。"}
]

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {         
            tabsKey:0,                  
        };
    } 
    changgAbouts(key){
        console.log(key);
        this.setState({tabsKey:key-1})
    }
   
    render() {
        const { tabsKey } = this.state;
        return (
            <div className="homepage">
                <div className="common-banner">
                   <div className="container">
                      <div className="banner-title-line"></div>
                      <h2>关于大赛</h2>
                   </div>
                </div>
                <div className="about-box layout common-abouts-nav">
                    <div className="container">
                        <div className="about-content">
                            <div className="about-nav">
                                <ul>
                                    {
                                        ['大赛介绍','评委简介','注册协议'].map((item,index)=>(
                                            <li key={index} className={tabsKey === index ? 'active':' '}  
                                            onClick={(e)=>this.setState({tabsKey:index})}>{item}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                            <Tabs defaultActiveKey="1" onChange={(key) => this.changgAbouts(key)}>
                                <TabPane tab="大赛介绍" key="1">                               
                                </TabPane>
                                <TabPane tab="评委简介" key="2">                               
                                </TabPane>
                                <TabPane tab="注册协议" key="3">                               
                                </TabPane>
                            </Tabs>
                            <div className="about-right">
                              <div className="about-right-box">
                              {
                                  tabsKey === 0 ?                             
                                  <div className="about-right-sort scrollbar">
                                    <h2 className="about-describe"><span>庆祝新中国成立70周年</span><span>中国报道“祖国，你好”主题摄影大赛</span></h2>
                                    <span className="red-line"></span>
                                    <div className="about-summary">
                                        <p className="about-black">征稿启事</p>
                                        <p>“祖国，你好”主题摄影大赛，以“个人”与“国家”的关系为视角，用摄影作品生动展现新中国成立70周年以来取得的辉煌成就，祖国的壮美山河以及中国人民追求幸福生活、努力奋斗的精神风貌</p>
                                        <p>二、活动目的</p>
                                        <p>在新中国成立70周年之际，通过摄影作品的形式，展示和介绍祖国的魅力河山、新时代的城乡面貌、人民群众的美好生活；为广大摄影爱好者打造交流和展示的平台；讲好中国故事，向世界说明中国。</p>
                                        <p><span className="about-black">指导单位：</span>中国外文局</p>
                                        <p><span className="about-black">主办单位：</span>中国报道杂志社</p>
                                        <p><span className="about-black">承办单位：</span>中国报道网</p>
                                        <p><span className="about-black">官方网站：</span>中国报道网<a href="http://www.chinareports.org.cn" target="_blank">（www.chinareports.org.cn）</a></p>
                                        <p className="about-black">征集时间</p>
                                        <p>作品征集时间：2019年3月1日——2019年9月30日（以收到作品时间为准）。</p>
                                        <p className="about-black">征集内容</p>
                                        <p>申报作品要求以“祖国，你好”为主题，按照城市印象、乡村振兴、美好生活、大美中国、新时代的奋斗者五个主题投稿。</p>
                                        <p className="about-black">入展作品荣誉设置及稿酬</p>
                                        <p>一等奖5名（每组1名），奖品：证书、奖金或等价奖品5000元；</p>
                                        <p>二等奖15名（每组3名），奖品：证书、奖金或等价奖品1000元；</p>
                                        <p>三等奖30名（每组6名），奖品：证书、奖金或等价奖品500元</p>
                                        <p>2、本次摄影大赛同时设置<span className="about-black">摄影基地评选活动</span>，其中：</p>
                                        <p>城市印象组 入围城市 50个，奖品：证书、铜牌。</p>
                                        <p>乡村振兴组 入围乡镇50个，奖品：证书、铜牌。</p>
                                        <p>大美中国组 入围旅游景区50个，奖品：证书、铜牌。</p>
                                        <p className="about-black">征稿细则</p>
                                        <p>1.广大摄影爱好者均可投稿。</p>
                                        <p>2.作品拍摄时间不限（鼓励近期拍摄的作品），彩色、黑白不限，组照4-8幅</p>
                                        <p>3.主办单位将统一调取作品的大尺寸数据文件（JPG最高格式压缩文件建议不低于5MB）。请投稿者务必于接到通知后将大尺寸数字文件（胶片拍摄的请扫描底片）在规定的时间内向主办单位提交，逾期不提供者视为自动放弃资格。</p>
                                        <p>4.该展实行作品公示制度，拟入展作品将在该展官方网站——中国报道网<a href="http://www.chinareports.org.cn" target="_blank">（www.chinareports.org.cn）</a>进行为期7天的公示，并在此期间接受社会监督。公示期满后，中国报道杂志将刊登入展作品精选、中国报道网将公布入展名单。</p>                                        
                                        <p>6.对于入展作品，主承办单位有权在著作权存续期内，以复制、发行、展览、放映、信息网络传播、汇编等方式使用入展作品，并不再支付报酬。</p>
                                        <p>7.投稿者应保证其为所投送作品的作者，并对该作品的整体及局部均拥有独立、完整、明确、无争议的著作权；投稿者还应保证其所投送的作品不侵犯第三人的包括著作权、肖像权、名誉权、隐私权等在内的合法权益。</p>
                                        <p>8.对于足以妨害公序良俗的作品及行为，一经发现将立即取消入展资格。“妨害公序良俗的作品及行为”包括但不限于可能严重误导公众认知、具有欺诈性质等一切违反法律、道德、公共秩序或善良风俗的情形。</p>
                                        <p>9.不符合本征稿启事规定的，不能参加评选；已评选的，将予取消；主办单位有权收回作品稿酬、荣誉证书等，并可在媒体上通报。</p>
                                        <p>10.本次活动不收参加费，不退稿，且不退存储介质。投稿作品在邮寄、发送过程中损毁、灭失或迟到、未到的，相关损失及后果由投稿者自行承担。</p>
                                        <p>11.本征稿启事解释权属于主承办单位。凡投稿者，即视为其已同意本征稿启事之所有规定。</p>
                                        <p className="about-black">作品评选</p>
                                        <p>由主办单位组织专家、学者进行评审。</p> 
                                        <p className="about-black">投稿须知</p> 
                                        <p>1.网络投稿</p> 
                                        <p>作者可在中国报道网“祖国你好摄影大赛参展”专区<a href="http://www.chinareports.org.cn" target="_blank">（www.chinareports.org.cn）</a>中注册，并按要求填写真实个人信息后上传作品。同时关注微信公众平台“祖国你好摄影大赛”，方能生效。注册之后按照规定程序上传参评作品。</p> 
                                        <p>主办单位保证不公开或泄露投稿个人非公开信息。投稿作品为JPG格式，图片统一处理为长边600-1000像素，文件大小控制在500KB以内。工作人员将对投稿作品进行初步审核，符合要求的作品将呈现于大展官方网站的作品列表中。</p>                                                                          
                                    </div>
                                  </div> :null
                              }
                              {
                                 tabsKey === 1 ?
                                 <div className="about-right-sort scrollbar">
                                    <h2 className="about-describe">评委简介</h2>
                                    <span className="red-line"></span>
                                    <div className="about-summary">
                                        <ul>
                                            {
                                              homeJudges.map((item,index)=>(
                                                  <li key={index}>
                                                     <div className="judges-avatar"><img src={item.img} alt=""></img></div>
                                                     <div className="judges-name">{item.name}</div>
                                                     <div className="judges-summary">
                                                       {
                                                          item.info.split(' ').map((item2,index2)=>(
                                                              <p key={index2}>{item2}</p>
                                                          )) 
                                                       }
                                                     </div>
                                                  </li>
                                              ))  
                                            }
                                        </ul>
                                     </div>
                                 </div> :null 
                              }
                              {
                                  tabsKey === 2 ?
                                  <div className="about-right-sort">
                                     <h2 className="about-describe">注册协议</h2>
                                     <span className="red-line"></span>
                                     <div className="about-summary">                                        
                                        <p>本协议是您与<span className="about-black">“祖国，你好”主题摄影大赛</span>网站（简称"本站"，网址：<a href="#">http://photo.qingtime.cn</a>）所有者（以下简称为"本站"）之间就本站服务等相关事宜所订立的契约，请您仔细阅读本注册协议，您点击"同意并继续"按钮后，本协议即构成对双方有约束力的法律文件。</p>
                                        <p>第1条 本站服务条款的确认和接纳</p>
                                        <p>1.1本站的各项电子服务的所有权和运作权归本站所有。用户同意所有注册协议条款并完成注册程序，才能成为本站的正式用户。用户确认：本协议条款是处理双方权利义务的契约，始终有效，法律另有强制性规定或双方另有特别约定的，依其规定。</p>
                                        <p>1.2用户点击同意本协议的，即视为用户确认自己具有享受本站服务、下单购物等相应的权利能力和行为能力，能够独立承担法律责任。</p>
                                        <p>1.3如果您在18周岁以下，您只能在父母或监护人的监护参与下才能使用本站。</p>
                                        <p>1.4本站保留在中华人民共和国大陆地区法施行之法律允许的范围内独自决定拒绝服务、关闭用户账户、清除或编辑内容或取消订单的权利。</p>                                      
                                        <p className="about-black">第2条 本站服务</p>
                                        <p>2.1本站通过互联网依法为用户提供互联网信息等服务，用户在完全同意本协议及本站规定的情况下，方有权使用本站的相关服务。</p>
                                        <p>2.2用户必须自行准备如下设备和承担如下开支：（1）上网设备，包括并不限于电脑或者其他上网终端、调制解调器及其他必备的上网装置；（2）上网开支，包括并不限于网络接入费、上网设备租用费、手机流量费等。</p>                                       
                                        <p className="about-black">第3条 用户信息</p>
                                        <p>3.1用户应自行诚信向本站提供注册资料，用户同意其提供的注册资料真实、准确、完整、合法有效，用户注册资料如有变动的，应及时更新其注册资料。如果用户提供的注册资料不合法、不真实、不准确、不详尽的，用户需承担因此引起的相应责任及后果，并且本站保留终止用户使用本站各项服务的权利。</p>
                                        <p>3.2用户在本站进行浏览、下单购物等活动时，涉及用户真实姓名/名称、通信地址、联系电话、电子邮箱等隐私信息的，本站将予以严格保密，除非得到用户的授权或法律另有规定，本站不会向外界披露用户隐私信息。</p>
                                        <p>3.3用户注册成功后，将产生用户名和密码等账户信息，您可以根据本站规定改变您的密码。用户应谨慎合理的保存、使用其用户名和密码。用户若发现任何非法使用用户账号或存在安全漏洞的情况，请立即通知本站并向公安机关报案。</p>
                                        <p>3.4用户同意，本站拥有通过邮件、短信电话等形式，向在本站注册、购物用户、收货人发送订单信息、促销活动等告知信息的权利。</p>
                                        <p>3.5用户不得将在本站注册获得的账户借给他人使用，否则用户应承担由此产生的全部责任，并与实际使用人承担连带责任。</p>
                                        <p>3.6用户同意，本站有权使用用户的注册信息、用户名、密码等信息，登录进入用户的注册账户，进行证据保全，包括但不限于公证、见证等。</p>
                                        <p className="about-black">第4条 用户依法言行义务</p>
                                        <p>本协议依据国家相关法律法规规章制定，用户同意严格遵守以下义务：</p>
                                        <p>（1）不得传输或发表：煽动抗拒、破坏宪法和法律、行政法规实施的言论，煽动颠覆国家政权，推翻社会主义制度的言论，煽动分裂国家、破坏国家统一的的言论，煽动民族仇恨、民族歧视、破坏民族团结的言论；</p>
                                        <p>（2）从中国大陆向境外传输资料信息时必须符合中国有关法规；</p>
                                        <p>（3）不得利用本站从事洗钱、窃取商业秘密、窃取个人信息等违法犯罪活动；</p>
                                        <p>（4）不得干扰本站的正常运转，不得侵入本站及国家计算机信息系统；</p>
                                        <p>（5）不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的，淫秽的、不文明的等信息资料；</p>
                                        <p>（6）不得传输或发表损害国家社会公共利益和涉及国家安全的信息资料或言论；</p>
                                        <p>（7）不得教唆他人从事本条所禁止的行为；</p>
                                        <p>（8）不得利用在本站注册的账户进行牟利性经营活动；</p>
                                        <p>（9）不得发布任何侵犯他人著作权、商标权等知识产权或合法权利的内容；</p>
                                        <p>用户应不时关注并遵守本站不时公布或修改的各类合法规则规定。</p>
                                        <p className="about-black">本站保有删除站内各类不符合法律政策或不真实的信息内容而无须通知用户的权利。</p>
                                        <p><span className="about-black">若用户未遵守以上规定的，本站有权作出独立判断并采取暂停或关闭用户帐号等措施。</span>用户须对自己在网上的言论和行为承担法律责任</p> 
                                        <p className="about-black">第5条 商品信息</p> 
                                        <p>本站上的商品价格、数量、是否有货等商品信息随时都有可能发生变动，本站不作特别通知。由于网站上商品信息的数量极其庞大，虽然本站会尽最大努力保证您所浏览商品信息的准确性，但由于众所周知的互联网技术因素等客观原因存在，本站网页显示的信息可能会有一定的滞后性或差错，对此情形您知悉并理解；本站欢迎纠错，并会视情况给予纠错者一定的奖励。</p>
                                        <p>为表述便利，商品和服务简称为"商品"或"货物"。</p>
                                        <p className="about-black">第6条 订单</p>
                                        <p>6.1在您下订单时，请您仔细确认所购商品的名称、价格、数量、型号、规格、尺寸、联系地址、电话、收货人等信息。收货人与用户本人不一致的，收货人的行为和意思表示视为用户的行为和意思表示，用户应对收货人的行为及意思表示的法律后果承担连带责任。</p>
                                        <p>6.2除法律另有强制性规定外，双方约定如下：本站上销售方展示的商品和价格等信息仅仅是交易信息的发布，您下单时须填写您希望购买的商品数量、价款及支付方式、收货人、联系方式、收货地址等内容；系统生成的订单信息是计算机信息系统根据您填写的内容自动生成的数据，仅是您向销售方发出的交易诉求；销售方收到您的订单信息后，只有在销售方将您在订单中订购的商品从仓库实际直接向您发出时（ 以商品出库为标志），方视为您与销售方之间就实际直接向您发出的商品建立了交易关系；如果您在一份订单里订购了多种商品并且销售方只给您发出了部分商品时，您与销售方之间仅就实际直接向您发出的商品建立了交易关系；只有在销售方实际直接向您发出了订单中订购的其他商品时，您和销售方之间就订单中该其他已实际直接向您发出的商品才成立交易关系。您可以随时登录您在本站注册的账户，查询您的订单状态。</p>
                                        <p>6.3由于市场变化及各种以合理商业努力难以控制的因素的影响，本站无法保证您提交的订单信息中希望购买的商品都会有货；如您拟购买的商品，发生缺货，您有权取消订单。</p>
                                        <p className="about-black">第7条 配送</p>
                                        <p>7.1销售方将会把商品（货物）送到您所指定的收货地址，所有在本站上列出的送货时间为参考时间，参考时间的计算是根据库存状况、正常的处理过程和送货时间、送货地点的基础上估计得出的。</p>
                                        <p>7.2因如下情况造成订单延迟或无法配送等，销售方不承担延迟配送的责任：</p>
                                        <p>（1）用户提供的信息错误、地址不详细等原因导致的；</p>
                                        <p>（2）货物送达后无人签收，导致无法配送或延迟配送的；</p>
                                        <p>（3）情势变更因素导致的；</p>
                                        <p>（4）不可抗力因素导致的，例如：自然灾害、交通戒严、突发战争等。</p>
                                        <p className="about-black">第8条 所有权及知识产权条款</p>
                                        <p>8.1用户一旦接受本协议，即表明该用户主动将其在任何时间段在本站发表的任何形式的信息内容（包括但不限于客户评价、客户咨询、各类话题文章等信息内容）的财产性权利等任何可转让的权利，如著作权财产权（包括并不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权以及应当由著作权人享有的其他可转让权利），全部独家且不可撤销地转让给本站所有，用户同意本站有权就任何主体侵权而单独提起诉讼。</p>
                                        <p>8.2本协议已经构成《中华人民共和国著作权法》第二十五条（条文序号依照2011年版著作权法确定）及相关法律规定的著作财产权等权利转让书面协议，其效力及于用户在本站网站上发布的任何受著作权法保护的作品内容，无论该等内容形成于本协议订立前还是本协议订立后。</p>
                                        <p>8.3用户同意并已充分了解本协议的条款，承诺不将已发表于本站的信息，以任何形式发布或授权其它主体以任何方式使用（包括但不限于在各类网站、媒体上使用）。</p>
                                        <p>8.4本站是本站的制作者,拥有此网站内容及资源的著作权等合法权利,受国家法律保护,有权不时地对本协议及本站的内容进行修改，并在本站张贴，无须另行通知用户。在法律允许的最大限度范围内，本站对本协议及本站内容拥有解释权。</p>
                                        <p>8.5除法律另有强制性规定外，未经本站明确的特别书面许可,任何单位或个人不得以任何方式非法地全部或部分复制、转载、引用、链接、抓取或以其他方式使用本站的信息内容，否则，本站有权追究其法律责任。</p>
                                        <p>8.6本站所刊登的资料信息（诸如文字、图表、标识、按钮图标、图像、声音文件片段、数字下载、数据编辑和软件），均是本站或其内容提供者的财产，受中国和国际版权法的保护。本站上所有内容的汇编是本站的排他财产，受中国和国际版权法的保护。本站上所有软件都是本站或其关联公司或其软件供应商的财产，受中国和国际版权法的保护。</p>
                                        <p className="about-black">第9条 责任限制及不承诺担保</p>
                                        <p>除非另有明确的书面说明,本站及其所包含的或以其它方式通过本站提供给您的全部信息、内容、材料、产品（包括软件）和服务，均是在"按现状"和"按现有"的基础上提供的。</p>
                                        <p>除非另有明确的书面说明,本站不对本站的运营及其包含在本网站上的信息、内容、材料、产品（包括软件）或服务作任何形式的、明示或默示的声明或担保（根据中华人民共和国法律另有规定的以外）。</p>
                                        <p>本站不担保本站所包含的或以其它方式通过本站提供给您的全部信息、内容、材料、产品（包括软件）和服务、其服务器或从本站发出的电子信件、信息没有病毒或其他有害成分。</p>
                                        <p>如因不可抗力或其它本站无法控制的原因使本站销售系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记录等，本站会合理地尽力协助处理善后事宜。</p>
                                        <p className="about-black">第10条 协议更新及用户关注义务</p>
                                        <p>根据国家法律法规变化及网站运营需要，本站有权对本协议条款不时地进行修改，修改后的协议一旦被张贴在本站上即生效，并代替原来的协议。用户可随时登录查阅最新协议； 用户有义务不时关注并阅读最新版的协议及网站公告。如用户不同意更新后的协议，可以且应立即停止接受本站网站依据本协议提供的服务；如用户继续使用本网站提供的服务的，即视为同意更新后的协议。本站建议您在使用本站之前阅读本协议及本站的公告。 如果本协议中任何一条被视为废止、无效或因任何理由不可执行，该条应视为可分的且并不影响任何其余条款的有效性和可执行性。</p>
                                        <p className="about-black">第11条 法律管辖和适用</p>
                                        <p>本协议的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用之有效法律（但不包括其冲突法规则）。</p>
                                        <p className="about-black">第12条 其他</p>
                                        <p>12.1本站网站所有者是指在政府部门依法许可或备案的本站网站经营主体。</p>
                                        <p>12.2本站尊重用户和消费者的合法权利，本协议及本网站上发布的各类规则、声明等其他内容，均是为了更好的、更加便利的为用户和消费者提供服务。本站欢迎用户和社会各界提出意见和建议，本站将虚心接受并适时修改本协议及本站上的各类规则。</p>
                                        <p>12.3本协议内容中以黑体、加粗、下划线、斜体等方式显著标识的条款，请用户着重阅读。</p>
                                        <p>12.4您点击本协议下方的"同意并继续"按钮即视为您完全接受本协议，在点击之前请您再次确认已知悉并完全理解本协议的全部内容。</p>
                                        <p>特别备注：在本网站电商频道选购商品时，如遇商城工作人员调整网站商品价格期间（或产品维护期间），商品标注价格远低于同品牌型号产品市场销售价格，此期间内已产生的订单（不论是否支付）将自动失效，已付款项将原路返回您的账户。</p>                                      
                                     </div>
                                  </div> :null
                              }
                              </div>
                            </div>
                        </div>                        
                    </div>                   
                </div>               
                <Footer/>
            </div>
        );
    };
}

export default About;