import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import Footer from '../../src/components/Footer';
import './NewsDetails.css';
import { GetQueryString,} from '../../src/ADS';
import moment from 'moment';
import { getStoryDetail } from '../actions/app';
import FroalaEditorView from 'react-froala-wysiwyg/FroalaEditorView';
const mapStateToProps = state => ({ 
    storyDetail:state.story.storyDetail,
});
class News extends Component {
    constructor(props) {
        super(props);
        this.state = {       
            tabsKey:0,                 
        };
    }
    render() {
        const { tabsKey } = this.state;
        const { storyDetail,} = this.props;
        console.log('storyDetail',storyDetail);
        return (
            <div className="homepage">
                <div className="common-banner news-banner">
                   <div className="container">
                      <div className="banner-title-line"></div>
                      <h2>大赛新闻</h2>
                   </div>
                </div>
                <div className="about-box layout">
                    <div className="container">
                        <div className="about-content">
                            
                            <div className="about-nav">
                            <ul>
                                {
                                    ['全国大赛新闻'].map((item,index)=>(
                                        <li key={index} className={tabsKey === index ? 'active':' '}  
                                        onClick={(e)=>this.setState({tabsKey:index})}><Link to="/news">{item}</Link></li>
                                    ))
                                }
                            </ul>
                            </div>
                            <div className="about-right">
                              <div className="news-right-details" style={{padding:'20px 20px 20px'}}>
                                <div className="go-back">
                                    <Link to="/news"><img src="image/icon/go-back.svg" alt=""></img>返回上一级</Link>
                                </div>
                                     <h2>{storyDetail.title}</h2> 
                                  <div className="news-details-tip">
                                     <span>大赛新闻</span>
                                     <span>{moment(storyDetail.createTime).format('YYYY-MM-DD HH:mm')}</span>
                                  </div>
                                  <div className="news-details-p">
                                     <FroalaEditorView model={storyDetail.content}></FroalaEditorView> 
                                  </div>
                                  {/* <div className="news-details-p">
                                      <div dangerouslySetInnerHTML={{ __html: storyDetail.content }}></div> 
                                      <p> {competitionInfo.content ? competitionInfo.content.replace(/<\/?.+?>/g, '') : ''}</p>
                                     {
                                        competitionInfo.content && competitionInfo.content.indexOf('img') !=-1 ?
                                        <div style={{textAlign:'left',marginTop:'10px',marginBottom:'10px'}}>
                                          {
                                              getImg(competitionInfo.content).map((item,index)=>(
                                                <img key={index} src={item} alt=""></img>
                                              ))
                                          }
                                        </div> :null
                                     } 
                                  </div>                                                              */}
                              </div>
                            </div>
                        </div>                        
                    </div>                   
                </div>
                <Footer/>    
            </div>
        );
    };
    componentDidMount() { 
        const { getStoryDetail } = this.props;       
        let  _key = GetQueryString("key");
        getStoryDetail(_key);
    }
}
export default connect(
    mapStateToProps,
    {  getStoryDetail },
)(News);
