import {  GET_NEWS,GET_PHONE_NAV } from '../actions/app';
const defaultState = { 
    news: [],
    phoneNavState:false,
    newsTotalNumber:null,
    isNewsData:false,
};

const auth = (state = defaultState, action) => {
    switch (action.type) {       
        // 新闻列表
        case GET_NEWS:
            let isNewsData = false;            
            if (!action.error) { 
                if (action.payload.result.length) {
                    isNewsData = false;
                } else{
                    isNewsData = true;
                }            
                return {
                    ...state,
                    news: action.payload.result,
                    newsTotalNumber:action.payload.totalNumber,
                    isNewsData:isNewsData
                };
            } else {
                return state;
            }
        case GET_PHONE_NAV:
            return {
                ...state,
                phoneNavState:action.phoneNavState
            }
        default:
            return state;
    }
};

export default auth;
