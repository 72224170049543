import {
    GET_STORY_INFO,
    GET_STORY_ALBUM,
    GET_STORY_DETAILS,   
} from '../actions/app';

const defaultState = {
    storyInfo:{},  
    storyNav:[],
    storyList:[], 
    storyDetail:{},
    storyTotalNumber: null,
    isData:false,
};

const story = (state = defaultState, action) => {
    switch (action.type) {
        // 获取参赛作品nav
        case GET_STORY_INFO:
        if (!action.error) {
            return {
                ...state,
                storyInfo: action.payload.result,  
                storyNav:action.payload.result.seriesInfo       
            }; 
        } else{
            return state;
        }
                  
       case GET_STORY_ALBUM:           
            let isData = false;            
            if (!action.error) {
                if (action.payload.result.length) {
                    isData = false;
                } else{
                    isData = true;
                }
                return {
                    ...state,
                    storyList: action.payload.result,
                    storyTotalNumber: action.payload.totalNumber,
                    isData:isData
                };
            }else{
                return state;
            }
             
       case GET_STORY_DETAILS:
            if (!action.error) {
                return {
                    ...state,
                    storyDetail: action.payload.result,         
                };
            }else{
                return state;
            }             
        default:
            return state;
    }
};

export default story;
