import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, } from "react-router-dom";
import { Spin,BackTop } from 'antd';
import { connect } from 'react-redux';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import News from './components/News';
import NewsDetails from './components/NewsDetails';
import Updates from './components/Updates';
import Works from './components/Works'; 
import WorksDetails from './components/WorksDetails';
import Awards from './components/Awards'; 
import AwardsDetails from './components/AwardsDetails';
import Review from './components/Review';
import Contact from './components/Contact';

const mapStateToProps = state => ({
  loading: state.common.loading,
})

class App extends Component {
  render() {
    const { loading } = this.props;
    return (
      <Router>
        <div className="app">
          <Header />  
          <BackTop>
              <div className="ant-back-top-inner"><img src="image/icon/backTop-arrow.svg" alt=""></img></div>
          </BackTop>    
          <div className="app-content">          
            <Route exact path="/" component={Home} />
            <Route path="/about" component={About} />
            <Route path="/news" component={News} /> 
            <Route path="/newsDetails" component={NewsDetails} />
            <Route path="/updates" component={Updates} />
            <Route path="/works" component={Works} /> 
            <Route path="/worksDetails" component={WorksDetails} /> 
            <Route path="/awards" component={Awards} />
            <Route path="/awardsDetails" component={AwardsDetails} />
            <Route path="/review" component={Review} />
            <Route path="/contact" component={Contact} />      
          </div> 
          {/* <Footer />        */}
          {loading ? (
            <div className="loading-mask">
              <Spin size="large" />
            </div>
          ) : null}
        </div>
      </Router>
    );
  }
}

export default connect(
  mapStateToProps,
  {},
)(App);
