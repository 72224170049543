import React, { Component } from 'react';
import './WorksDetails.css';
import {Link} from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import Footer from '../../src/components/Footer';
import { GetQueryString } from '../../src/ADS';
import { getStoryDetail } from '../actions/app';
const mapStateToProps = state => ({   
    storyDetail:state.story.storyDetail,
});

// const worksItem =[
//     {"title":"祖国的花朵","sort":"人物特写","read":"200","like":"23","img":"image/workslist1.png","auth":"张小兵","area":"上海",},
//     {"title":"绿化祖国","sort":"大美中国","read":"0","like":"1","img":"image/workslist2.png","auth":"帆帆","area":"南京"},
//     {"title":"画中的长城","sort":"大美中国","read":"10","like":"23","img":"image/workslist3.png","auth":"温嘻嘻","area":"苏州"},
//     {"title":"祖国的花朵","sort":"人物特写","read":"20","like":"23","img":"image/workslist4.png","auth":"张三","area":"乌鲁木齐"},
//     {"title":"祖国的花朵","sort":"人物特写","read":"20","like":"23","img":"image/workslist4.png","auth":"张三","area":"安徽"},    
//     {"title":"画中的长城","sort":"大美中国","read":"10","like":"23","img":"image/workslist3.png","auth":"温嘻嘻","area":"大连"},
//     {"title":"绿化祖国","sort":"大美中国","read":"0","like":"1","img":"image/workslist2.png","auth":"帆帆","area":"青岛"},
//     {"title":"祖国的花朵","sort":"人物特写","read":"200","like":"23","img":"image/workslist1.png","auth":"张小兵","area":"上海"}
// ]
class WorksDetails extends Component { 
    constructor(props) {
        super(props);
        this.state = {         
           index:0,                  
        };
    } 
    render() {     
        const {storyDetail} = this.props;       
        let avatar = storyDetail.creator && storyDetail.creator.avatar ? storyDetail.creator.avatar :'/image/icon/avatar.svg';
        let imglist = [];
        if(storyDetail.richContent && storyDetail.richContent.length){
            storyDetail.richContent.map((item,index)=>{
                if(storyDetail.cover && storyDetail.cover === item.url){
                    storyDetail.richContent.splice(index,1);
                    imglist = storyDetail.richContent;
                    console.log('imglist',imglist);
                }
            })
        }
        return (
            <div className="homepage">
               <div className="common-banner works-banner">
                   <div className="container">
                      <div className="banner-title-line"></div>
                      <h2>参赛作品</h2>
                   </div>
                </div>
                <div className="works-details-box layout">
                   <div className="container">
                      <div className="works-details-content">
                            <div className="go-back">
                               <Link to="/works"><img src="image/icon/go-back.svg" alt=""></img>返回上一级</Link>
                            </div>
                            <div className="works-details-top">                             
                             <div className="works-details-top-left">
                               <h2>{storyDetail.title} <img src="image/icon/hot-icon.svg" alt=""></img></h2>
                               <div className="works-left-tip">
                                   <div className="works-top-sorts">
                                      <span>{storyDetail.series && storyDetail.series.name ? storyDetail.series.name :''}</span>                                   
                                      <span>{moment(storyDetail.createTime).format('YYYY-MM-DD')}</span>
                                    </div>
                                   <div className="works-options">                                       
                                       <div className="works-opertion">
                                            <span><img src="image/icon/read-icon.svg" alt=""></img>{storyDetail.clickNumber}</span>
                                            <span><img src="image/icon/zan-icon.svg" alt=""></img>{storyDetail.likeNumber}</span>
                                       </div>
                                   </div>
                               </div>
                             </div>
                             <div className="works-details-top-right">
                                <div className="works-avatar" style={{backgroundImage:'url('+avatar+'?imageView2/1/w/160/h/160)'}}></div>
                                <div className="works-avatar-describe">
                                   <h3>{storyDetail.creator && storyDetail.creator.name ? storyDetail.creator.name :''}</h3>
                                   <p>{storyDetail.address}</p>
                                </div>
                             </div>
                         </div>
                         {
                             storyDetail.cover ?
                             <div className="cover" style={{marginBottom:'10px'}}>
                                <a href={storyDetail.cover} target="_blank"><img src={storyDetail.cover+'?imageView2/2/w/1140/'} alt=""></img></a> 
                             </div> :null
                         }                       
                         <div className="works-details-summary">
                            {
                                storyDetail.richContent && storyDetail.richContent.length ?
                                storyDetail.richContent.map((item,key)=>(
                                <p key={key}>
                                        {
                                            item.metaType === 'image' && item.memo ?
                                                <span style={{ textAlign: 'left', display: 'block', lineHeight: '25px' }}>{item.memo}</span> : null
                                        }
                                        {
                                            item.metaType === 'image' ?
                                                <a href={item.url} target="_blank"><img className="story-image" src={item.url+'?imageView2/2/w/1140/'} alt=""></img></a> : null
                                        }
                                    </p>                                 
                                )):null 
                            }                            
                         </div>
                      </div>                 
                   </div>
                </div>
                <Footer/>                  
            </div>
        );
    };
    componentDidMount() {             
        let _key = GetQueryString("index");
        // this.setState({index:_key});  
        this.props.getStoryDetail(_key);   
    }
   
}

export default connect(
    mapStateToProps,
    { getStoryDetail  },
)(WorksDetails);
