import api from '../services/Api';
//phone-nav
export const GET_PHONE_NAV = 'GET_PHONE_NAV';
export function getphoneNav(phoneNavState) {    
    return { type: GET_PHONE_NAV, phoneNavState: phoneNavState }
}

// common
export const ASYNC_START = 'ASYNC_START';
export const ASYNC_END = 'ASYNC_END';

// auth
export const GET_USER_INFO = 'GET_USER_INFO';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';

export function logout(history) {
    history.push('/home');
    return { type: LOGOUT }
}
// home
export const GET_NEWS = 'GET_NEWS';

export function getNews(starKey,seriesKey, curPage, perPage) {
    let request = api.news.getNews(starKey,seriesKey, curPage, perPage);
    return { type: GET_NEWS, payload: request }
}
// story
export const GET_STORY_INFO = 'GET_STORY_INFO';
export const GET_STORY_ALBUM = 'GET_STORY_ALBUM';//获取参赛作品列表 奖项分类
export const GET_STORY_DETAILS = 'GET_STORY_DETAILS'; //获取参赛作品详情

export function getStoryInfo(starKey){ 
    let request = api.story.getStoryinfo(starKey);
    return { type: GET_STORY_INFO, payload: request }
}

export function getStoryAlbum(param){ //获取参赛作品列表 地区分类
    let request = api.story.getStoryAlbum(param);
    return { type: GET_STORY_ALBUM, payload: request }
}


export function getStoryDetail(key){//获取参赛作品详情
    console.log('----------getStoryDetail');
    let request = api.story.getStoryDetail(key);
    return { type: GET_STORY_DETAILS, payload: request }
}