import React, { Component } from 'react';
import { connect } from 'react-redux';
import './Footer.css';

const mapStateToProps = state => ({
    webUrl:state.common.webUrl,   
});

class Footer extends Component {
    render() {      
        return (
                <div className="home-footer">
                   <div className="container">
                       <div className="home-footer-box clearfix">
                            <div className="home-footer-left">
                               <img src="image/foot-logo.svg" alt=""></img>
                               <span className="foot-btn" onClick={()=>window.open(this.props.webUrl)}>我要参赛</span>
                            </div>
                            <div className="home-footer-middle">
                               <p>版权所有　2019-2029　中国报道</p>
                               <p>Copyright ＠ 2005-2019　www.chinareports.org.cn  All rights reserved.</p>
                               <p>技术支持：江苏时光信息科技有限公司</p>
                            </div>
                            <div className="home-footer-right">
                                <ul>
                                    <li><img src="image/code1.png" alt=""></img><p>大赛报名官网</p></li>
                                    <li><img src="image/code2.jpg" alt=""></img><p>微信公众平台</p></li>
                                </ul>
                            </div>
                       </div>                       
                   </div>
                </div>
        );
    };
}

export default connect(mapStateToProps)(Footer)