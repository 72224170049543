function GetQueryString(urlParam) { //解析url参数
    let search = window.location.search;
    if (search && search.substr(0, 1) === '?' && search.substr(1)) {
        var reg = new RegExp('(^|&)' + urlParam + '=([^&]*)(&|$)');
        var r = window.location.search.substr(1).match(reg);
        if (r != null) return unescape(r[2]); return '';
    }
  }
  //判断是否是数字
var isNumber=function(value) {
    var patrn = /^(-)?\d+(\.\d+)?$/;
    if (patrn.exec(value) == null || value == "") {
        return false
    } else {
        return true
    }
}
// 匹配图片
function getImg(data){
    let imgsrc = [];
    var str = data;
    //匹配图片（g表示匹配所有结果i表示区分大小写）
    var imgReg = /<img.*?(?:>|\/>)/gi;
    //匹配src属性
    var srcReg = /src=[\'\"]?([^\'\"]*)[\'\"]?/i;
    var arr = str.match(imgReg);
    // console.log('所有已成功匹配图片的数组：'+arr);
    for (var i = 0; i < arr.length; i++) {
    var src = arr[i].match(srcReg);
    //获取图片地址 
    if(src[1]){
        imgsrc.push(src[1]);   
        return imgsrc;        
     }
    }
}
  export {GetQueryString,isNumber,getImg}