import React, { Component } from 'react';
import moment from 'moment';
import {Link} from 'react-router-dom';
import { Carousel, } from 'antd';
import Footer from '../../src/components/Footer';
import './Home.css';
import { connect } from 'react-redux';
import { getNews, getStoryDetail} from '../actions/app';


const mapStateToProps = state => ({
    webUrl:state.common.webUrl,  
    news: state.home.news,
});

// const aboutBtn = ["作品提交","大赛介绍","大赛章程","赛区列表","获奖名单"];
// const newsImg = [{"img":"image/news01.png"},{"img":"image/news02.png"},{"img":"image/news03.png"}];
const homeTime =[
    {"time":'4月1日',"title":"比赛启动","img":"image/icon/time1.png"},
    {"time":'9月1日',"title":"初审阶段","img":"image/icon/time2.png"},
    {"time":'9月15日',"title":"人气奖评选","img":"image/icon/time3.png"},
    {"time":'9月20日',"title":"专家评审","img":"image/icon/time4.png"},
    {"time":'10月1日',"title":"颁奖典礼","img":"image/icon/time5.png"},
    {"time":'11月1日',"title":"开始巡展","img":"image/icon/time6.png"}
]
// const homeJudges = [
//     {"name":'李舸',"identity":["中国摄影家协会主席","人民日报总编室部务委员","高级记者"],"img":"image/judges1.png"},
//     {"name":'曾星明',"identity":["《中国摄影家》杂志运营总监","中国记协理事","中国摄影家协会理事","中国摄影著作权协会理事"],"img":"image/judges2.png"},  
// ]
const areas =["北京赛区","天津赛区","河北赛区","山西赛区","内蒙古赛区","辽宁赛区","吉林赛区","黑龙江赛区","上海赛区","江苏赛区","浙江赛区","安徽赛区","福建赛区","江西赛区","山东赛区","河南赛区","湖北赛区","湖南赛区","广东赛区","广西赛区","四川赛区","重庆赛区","贵州赛区","云南赛区","陕西赛区","甘肃赛区","新疆赛区","海南赛区","宁夏赛区","海外赛区"];
const homeSupports = [
    {"img":"image/units01.png","href":"http://www.cipg.org.cn/"},
    {"img":"image/units02.png","href":"http://www.chinareports.org.cn"},
    {"img":"image/units03.png","href":""},
    {"img":"image/units04.png","href":"https://www.qingtime.com"},
    {"img":"image/units05.png","href":""},
    {"img":"image/units06.png","href":""},
    {"img":"image/units07.png","href":"https://mp.weixin.qq.com/s/x_w9zQ68d_Nk9_gR4VBJRQ"},
    {"img":"image/units08.png","href":""},
    {"img":"image/units09.png","href":""},
    {"img":"image/units10.png","href":""}
]
class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // banner高，使其正好全屏
            bannerHeight: window.innerHeight - 71,
            tabsKey:1,
            tabNews:0,
            tabAreas:8,
            activeArea:8,  
            starKey:'93088',   
            seriesKey:'602264202', 
        };
    }   
    // changeTitleTab(e,index){       
    //     this.setState({tabNews:index});
    //     this.props.getNews(index === 0 ? '589775513' : '590065185', 1, 3);
    // }     
    getNewsDetails(e,_key){       
        this.props.getStoryDetail(_key);
        this.props.history.push('/newsDetails?key='+_key);     
      }
    getPerosnalMsg(e){
        window.open(this.props.webUrl);  
    //    window.location.href="http://192.168.1.133:3000";    
    }
    selectIndexArea(e,index,name){       
        this.setState({tabAreas:index,activeArea:index});          
        window.open(this.props.webUrl); 
          
    }
    render() {
        const { bannerHeight, tabAreas } = this.state;
        const { news, } = this.props;
        console.log('news',news);
        return (            
            <div className="homepage">           
                <div className="home-banner" style={{ height: bannerHeight }}>                   
                   <div className="banner-title container">                    
                       {/* <div className="banner-logo">
                           <span><img src="image/banner-logo.png" alt=""></img></span>
                           <span><img src="image/banner-logo2.png" alt=""></img></span>
                        </div> */}
                       <div><img src="image/banner-logo.png" alt=""></img></div>
                       <div><img src="image/banner-title01.svg" alt=''></img></div>
                       <div><img src="image/banner-title02.svg" alt=''></img></div>
                       <div><img src="image/banner-title03.svg" alt=''></img></div>
                       <h2>主题摄影大赛</h2>
                       <div className="banner-btn" onClick={(e)=>this.getPerosnalMsg()}>我要参赛</div>
                   </div>
                </div>
                <div className="home-about" style={{textAlign:'center'}}>
                   <div className="container">
                        <h6 className="title-tip">About Contest</h6>                   
                        <span className="title">关于大赛</span>
                        <div className="about-detail">
                            {/* {competitionInfo.content ? competitionInfo.content.replace(/<\/?.+?>/g, '') : ''} */}
                            <p>“祖国，你好”主题摄影大赛，以“个人”与“国家”的关系为视角，</p>
                            <p>用摄影作品生动展现新中国成立70周年以来取得的辉煌成就，</p>
                            <p>祖国的壮美山河以及中国人民追求幸福生活、努力奋斗的精神风貌。</p>
                        </div>
                        <div className="more"><Link to='/about'>更多介绍<i className="more-icon"></i></Link></div>
                        {/* <div className="about-video">
                        <img src="image/about-play.svg" alt=''></img>
                        </div> */}
                        {/* <div className="about-btn">
                        <ul>
                            {
                                aboutBtn.map((item,key)=>{
                                    return (                                    
                                        <li className={tabsKey === key ? "active" : " "} key={key} onClick={(e)=>this.changeAboutbtn(e,key)}><div>{item}</div></li>
                                    )
                                })
                            }                                              
                        </ul>
                        </div> */}
                    </div>
                </div>
                <div className="home-news layout">
                    <div className="container">
                        <h6 className="title-tip">Contest News</h6>
                        <span className="title">大赛新闻</span>
                        {/* <div className="home-news-switch">
                            {
                                ['全国大赛新闻','地方赛区新闻'].map((item,index)=>{
                                    return (
                                        <span className={tabNews === index ? 'active' : ''} onClick={(e)=>this.changeTitleTab(e,index)} key={'tab'+index}>{item}</span>
                                    )
                                })
                            }
                            <span onClick={getNews.bind(this, '589775513', 1, 3)}>全国大赛新闻</span>
                            <span onClick={getNews.bind(this, '590065185', 1, 3)}>地方赛区新闻</span>
                        </div> */}
                        <div className="home-news-content clearfix">
                            <div className="home-news-left">
                                {
                                    news && news.length ?
                                
                                <ul>
                                    {
                                        news && news.map((item, index) => (   
                                        index < 3 ?                                     
                                        <li key={index} onClick={(e)=>this.getNewsDetails(e,item._key)}>
                                            {/* {item.content && item.content.indexOf('img') !=-1 ?
                                             <div className="news-left-img" style={{backgroundImage:'url('+getImg(item.content)[0]+')'}}>                                               
                                             </div> :
                                             <div className="news-left-img" style={{backgroundImage:'url(./image/news-common.png)',backgroundPosition:'center'}}></div>                             
                                            } */}
                                            {
                                               item.cover ? 
                                               <div className="news-left-img" style={{backgroundImage:'url('+item.cover+')'}}></div>:
                                               <div className="news-left-img" style={{backgroundImage:'url(./image/news-common.png)',backgroundPosition:'center'}}></div> 
                                            }  
                                            
                                            <div className="news-left-content">
                                               <h4 style={{WebkitBoxOrient: "vertical"}}>{item.title}</h4>
                                               {/* <p style={{WebkitBoxOrient:'vertical'}}>{(item.content.replace(/<\/?.+?>/g, '').replace('&nbsp',' '))}</p> */}
                                               <p style={{WebkitBoxOrient:'vertical'}}>{item.memo ? item.memo :''}</p>
                                               <span>{moment(item.time).format('YYYY-MM-DD HH:mm')}</span>
                                            </div>
                                        </li> :null
                                    ))
                                    }                                   
                               </ul>:null
                                }
                            </div>
                            <div className="home-news-right">                           
                            <Carousel autoplay>
                                <div className="carouse-img-box" >                                   
                                  <div className="carouse-img" style={{backgroundImage:'url(/image/carousel-01.png)'}}>
                                     <div className="carouse-img-bottom">
                                        <p>中国摄协2019年度4月份拟批准入会名单</p>
                                        <span>2019.5.16</span>
                                     </div>
                                  </div>
                                </div> 
                                <div className="carouse-img-box">                             
                                   <div className="carouse-img" style={{backgroundImage:'url(/image/carousel-02.png)'}}>
                                      <div className="carouse-img-bottom">
                                          <p>中国摄协活动大赛公开</p>
                                          <span>2019.5.10</span>
                                      </div>
                                   </div>
                                </div>                              
                            </Carousel>
                            </div>                            
                        </div>
                        <div className="more"><Link to='/news'>查看全部<i className="more-icon"></i></Link></div>
                    </div>
                </div>
                <div className="home-division-entrance layout">
                    <div className="container">
                        <h6 className="title-tip">Contest Areas</h6>
                        <span className="title">赛区入口</span>
                        <div className="home-division-entrance-box">
                           <div className="home-division-entrance-left">
                             <div className="home-division-entrance-left-box">                               
                                <img src="image/star-down.svg" alt=""></img>
                                <div className="home-division-area" style={{fontSize:(areas[this.state.activeArea].length > 2 ? '38px':'50px')}}>
                                {areas[this.state.activeArea]}</div>
                                <img src="image/star-up.svg" alt=""></img>
                             </div>
                           </div>
                           <div className="home-division-entrance-right">
                             <ul>
                                 {
                                     areas.map((item,index) => (
                                         <li key={index} className={tabAreas === index ? 'active':''} 
                                         onClick={(e)=>this.selectIndexArea(e,index,item)}
                                         onMouseEnter={(e)=>( this.setState({activeArea:index}))}><Link to=''>{item}</Link></li>    
                                     ))                                   
                                 }
                             </ul>
                           </div>
                        </div>
                    </div>
                </div>
                <div className="home-awards layout">
                   <div className="container">
                       <h6 className="title-tip">Contest Prizes</h6>
                       <span className="title">大赛奖项</span>
                       <div className="home-awards-box">
                          <ul>
                              {
                                 ['城市印象奖','乡村振兴奖','美好生活奖','大美中国奖','新时代的奋斗者奖'].map((item,index)=>(
                                    <li key={index}><div><p>{item}</p></div></li>
                                 ))
                              }
                          </ul>
                       </div>
                   </div>
                </div>
                <div className="home-time layout">
                   <div className="container">
                       <h6 className="title-tip">Contest Schedule</h6>
                       <span className="title">大赛时间安排</span>
                       <div className="home-time-box phone">
                          <img src="image/phone/phone-time-bg.svg" alt="" className="phone-time-img"></img>
                          <ul>
                              {
                                  homeTime.map((item,index)=>(
                                     <li key={index}>
                                        <p className="home-times">{item.time}</p>
                                        <span style={{backgroundImage:'url('+item.img+')'}}></span>
                                        <p className="home-time-title">{item.title}</p>
                                     </li>
                                  ))
                              }                           
                          </ul>
                       </div>
                   </div>
                </div>
                {/* <div className="home-judges layout">
                    <div className="container">
                       <h6 className="title-tip">Contest Judges</h6>
                       <span className="title">大赛评委</span>
                       <div className="home-judges-box">
                         <ul>
                              {
                                  homeJudges.map((item,index)=>(
                                     <li key={index}>
                                        <img src={item.img} alt=""></img>
                                        <h4>{item.name}</h4>
                                        <div className="home-judges-describe">
                                        {
                                            item.identity.map((item2,index2) =>(
                                                <p key={index2}>{item2}</p>
                                            ))
                                        }
                                          
                                        </div>
                                     </li>
                                  ))
                              }                           
                          </ul>
                       </div>
                    </div>                  
                </div> */}
                <div className="home-supports layout">
                    <div className="container">
                       <h6 className="title-tip">Supporting Unit</h6>
                       <span className="title">支持单位</span>
                       <div className="home-supports-box">
                           <ul>
                               {
                                   homeSupports.map((item,index) =>(
                                       
                                       <li key={index}>
                                          {item.href === '' ? <img src={item.img} alt=""></img> :
                                          <a href={item.href} target='_blank'>
                                          <img src={item.img} alt=""></img></a>
                                        }
                                          
                                     </li>
                                   ))
                               }                           
                           </ul>
                       </div>                      
                    </div>
                </div>               
                <Footer />                            
            </div>
        );
    };

    componentDidMount() {
        let that = this;
        // 添加resize事件，改变banner高度
        window.addEventListener('resize', () => {
            that.setState({
                bannerHeight: window.innerHeight - 71,
             
            })
        });

        const { getNews } = this.props;
        const {starKey,seriesKey} = this.state;
        getNews(starKey,seriesKey, 1, 3);
    
    }
}

export default connect(
    mapStateToProps,
    { getNews,getStoryDetail },
)(Home);