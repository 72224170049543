import React, { Component } from 'react';
import { connect } from 'react-redux';
import Footer from '../../src/components/Footer';
import './Updates.css';
const areas =["北京赛区","天津赛区","河北赛区","山西赛区","内蒙古赛区","辽宁赛区","吉林赛区","黑龙江赛区","上海赛区","江苏赛区","浙江赛区","安徽赛区","福建赛区","江西赛区","山东赛区","河南赛区","湖北赛区","湖南赛区","广东赛区","广西赛区","四川赛区","重庆赛区","贵州赛区","云南赛区","陕西赛区","甘肃赛区","新疆赛区","海南赛区","宁夏赛区","海外赛区"];
const mapStateToProps = state => ({
    webUrl:state.common.webUrl,   
    news: state.home.news,
});
class Updates extends Component { 
    constructor(props) {
        super(props);
        this.state = {         
            tabsKey:8,                  
        };
    } 
    selectIndexArea(e,index,name){
        // if(name === '海外赛区'){
        //     return
        // }else{
            this.setState({tabAreas:index,activeArea:index}); 
            // window.open('https://baoku.qingtime.cn/login?stationKey=93088');
            window.open(this.props.webUrl); 
        // }        
    }
    render() {
        const {tabsKey} = this.state;    
        return (
            <div className="homepage">
                <div className="common-banner updates-banner">
                   <div className="container">
                      <div className="banner-title-line"></div>
                      <h2>赛区动态</h2>
                   </div>
                </div>
                <div className="about-box layout">
                    <div className="container">
                        <div className="updates-content">                           
                            <ul>
                                {
                                    areas.map((item,index)=>(
                                        <li key={index} className={tabsKey === index ? 'active':''}
                                        onClick={(e)=>this.selectIndexArea(e,index,item)}><div>{item}</div></li>                                        
                                    ))
                                }
                            </ul>
                        </div>                        
                    </div>                   
                </div> 
                <Footer/>   
            </div>
        );
    };
   
}
export default connect(
    mapStateToProps,
    { },
)(Updates);
