import React, { Component } from 'react';
// import ClickOutside from '@react-web/click-outside';
import { connect } from 'react-redux';
import moment from 'moment';
import { isNumber } from '../../src/ADS';
import Footer from '../../src/components/Footer';
import './Works.css';
import { getStoryInfo,getStoryAlbum,getStoryDetail } from '../actions/app';

const mapStateToProps = state => ({
    storyNav: state.story.storyNav,
    storyList:state.story.storyList,
    storyTotalNumber:state.story.storyTotalNumber,
    isData:state.story.isData,
});

const area =["北京赛区","天津赛区","河北赛区","山西赛区","内蒙古赛区","辽宁赛区","吉林赛区","黑龙江赛区","上海赛区","江苏赛区","浙江赛区","安徽赛区","福建赛区","江西赛区","山东赛区","河南赛区","湖北赛区","湖南赛区","广东赛区","广西赛区","四川赛区","重庆赛区","贵州赛区","云南赛区","陕西赛区","甘肃赛区","新疆赛区","海南赛区","宁夏赛区","海外赛区"];
// const worksItem =[
//     {"title":"祖国的花朵","sort":"人物特写","read":"200","like":"23","img":"image/workslist1.png","auth":"张小兵","area":"上海",},
//     {"title":"绿化祖国","sort":"大美中国","read":"0","like":"1","img":"image/workslist2.png","auth":"帆帆","area":"南京"},
//     {"title":"画中的长城","sort":"大美中国","read":"10","like":"23","img":"image/workslist3.png","auth":"温嘻嘻","area":"苏州"},
//     {"title":"祖国的花朵","sort":"人物特写","read":"20","like":"23","img":"image/workslist4.png","auth":"张三","area":"乌鲁木齐"},
//     {"title":"祖国的花朵","sort":"人物特写","read":"20","like":"23","img":"image/workslist4.png","auth":"张三","area":"安徽"},    
//     {"title":"画中的长城","sort":"大美中国","read":"10","like":"23","img":"image/workslist3.png","auth":"温嘻嘻","area":"大连"},
//     {"title":"绿化祖国","sort":"大美中国","read":"0","like":"1","img":"image/workslist2.png","auth":"帆帆","area":"青岛"},
//     {"title":"祖国的花朵","sort":"人物特写","read":"200","like":"23","img":"image/workslist1.png","auth":"张小兵","area":"上海"}
// ]

class Works extends Component { 
    constructor(props) {
        super(props);
        this.state = {         
            tabsKey:0,
            awards:'', 
            awardsKey:-1,
            page:1, 
            limit:8,  
            isAll:true,       
            allSort:false,
            areaAort:false, 
            // starKey:'14137732091240538',
            starKey:'93088',
            seriesKey:'allSeries',
            AreaName:'',           
        };
    } 
    getStorySortList(e,name,_key){
        const { starKey,page,limit,tabsKey} = this.state;
        const { getStoryAlbum } = this.props; 
        let award = '',isAlls = false,seriesKeys ='';
        let param={};        
        if(_key){
            award = name;
            seriesKeys = _key;
        } else{
            award = '';
            seriesKeys = 'allSeries';
            isAlls = true;
        }       
        param = {
            type:1,
            starKey:starKey,
            seriesKey:seriesKeys,
            curPage:page,
            perPage: limit
         } 
        if(tabsKey === 1){
            param.tag = name;        
         }
        this.setState({seriesKey:seriesKeys,isAll:isAlls,awards:award,AreaName:name,allSort:false,areaAort:false,page: 1});
        getStoryAlbum(param);
    }
    getWorksDetails(e,_key){
        const { getStoryDetail } = this.props;
        getStoryDetail(_key);
        console.log('-------------------------------getWorksDetails');
        this.props.history.push('/worksDetails?index='+_key);     
    }
    changeWorkNav(e,index){
        let type = index;
        this.setState({tabsKey:index,allSort:!this.state.allSort,page:1});
        if(type === 0){
            this.setState({tabsKey:index,allSort:!this.state.allSort,areaAort:false});
        } else{
            this.setState({tabsKey:index,allSort:false,areaAort:!this.state.areaAort});
        }      
    }
    handleClickPageAjax(e,action,limit,page,pageCount,pagename){
        const {starKey,seriesKey,tabsKey,AreaName} = this.state;
        const { getStoryAlbum } = this.props;
        console.log('pageCount',pageCount);
        console.log('seriesKey',seriesKey);
        switch(action){
            case 'first':
              if(page === 1){
                return false;
              }
              page=1
              break;
            case 'prev':
            console.log('page',page);
              if(page <= 1){
                page=1;
                return false;
              }else{
                page--;
              }
              break;
            case 'next':
              if(page >= pageCount){
                page=pageCount;
                return false;
              }else{
                page++;
              }
              break;
            case 'last':
              if(page === pageCount){
                return false;
              }
              page=pageCount
              break;
          }
          this.setState({[pagename]:page});
          let param = {
            type:1,
            starKey:starKey,           
            curPage:page,
            perPage: limit
          } 
         if(tabsKey === 1){ //地区 
            param.seriesKey = 'allSeries';
            param.tag = AreaName;
         } else{ //奖项分类
            param.seriesKey = seriesKey
         }
        getStoryAlbum(param);
    }

    handleChangePageAjax_1(e,pageCount,pagename){
        this.setState({page:1});
        let page=e.target.value;
        console.log('page',page);
        console.log('pageCount',pageCount);
        if(isNumber(page)){
          if(page <= 1){
            page=1;
          }else if(page >= pageCount){
            page=pageCount;
          }
        }else{
          page=1;
        }
        this.setState({[pagename]:page});
   }

   //高级检索-分页-enter请求分页
   handleSendPageAjax_1(e,limit){
    const {starKey,seriesKey,page,tabsKey,AreaName} = this.state;
    const { getStoryAlbum } = this.props;
    console.log('page',page);
    let param = {
        type:1,
        starKey:starKey,           
        curPage:page,
        perPage: limit
      } 
     if(tabsKey === 1){ //地区
        param.seriesKey = 'allSeries';
        param.tag = AreaName;
     } else{ //奖项分类
        param.seriesKey = seriesKey
     }
    if(e.keyCode === 13){
        getStoryAlbum(param);
      }
   }    
    render() {
        const {tabsKey,awardsKey,allSort,areaAort,page,limit,isAll} = this.state;
        const {storyNav,storyList,storyTotalNumber,isData} = this.props;       
        let storyPageCount = Math.ceil(storyTotalNumber/limit);
        return (     
            <div className="homepage">
                <div className="common-banner works-banner">
                   <div className="container">
                      <div className="banner-title-line"></div>
                      <h2>参赛作品</h2>
                   </div>
                </div>
                <div className="works-box">
                   
                    <div className="works-nav-box">
                        <div className="container">
                            <div className="works-nav">
                                <ul>                                    
                                    {
                                        ['全部奖项','地区分类'].map((item,index)=>(
                                            <li key={index} className={tabsKey === index ? 'active' : ''}
                                            onClick={(e)=>this.changeWorkNav(e,index)}>
                                             <div>{item}<i></i></div>                                              
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>                        
                        {
                            allSort && tabsKey === 0 ?                          
                            <div  className={"all-menu "+(tabsKey === 0 ? 'show' :'')}>
                                <div className="container">
                                    <ul>
                                       <li className={isAll ? 'active':''} 
                                        onClick={(e)=>this.getStorySortList(e,'','')}>全部</li>
                                        {                                                
                                            storyNav.map((item,index)=>(
                                                <li key={index} className={this.state.awards === item.name ? 'active':' '}  
                                                onClick={(e)=>this.getStorySortList(e,item.name,item._key)}>{item.name+'奖'}</li>
                                            ))
                                        }
                                    </ul>
                                </div>                            
                          </div>:null                                                 
                        }                        
                        {
                            areaAort && tabsKey === 1?                           
                            <div className="all-menu area-menu">
                                <div className="container">
                                    <ul className="scrollbar">
                                        {
                                            area.map((item,index)=>(
                                                <li key={index} className={this.state.AreaName === item ? 'active':' '} 
                                                 onClick={(e)=>this.getStorySortList(e,item,'')}                                              
                                                 >{item}</li>
                                            ))
                                        }
                                    </ul>
                                </div>                            
                          </div>:null
                        }                                                 
                    </div>
                  
                    <div className="works-content layout">
                        <div className="container">                                              
                            <div className="works-list"> 
                            {
                                storyList.length ?                                                     
                                <ul>
                                   {
                                       storyList.map((item,index)=>(
                                        <li key={index} onClick={(e)=>this.getWorksDetails(e,item._key)}>
                                            <div className="works-list-item">
                                                <div className="works-pic" style={{backgroundImage:'url('+item.cover+'?imageView2/2/w/280/)',}}>
                                                    <span className="totalnum" style={{bottom:'10px',right:'10px'}}>{item.pictureCount}</span>
                                                </div> 
                                                <div className="works-list-summary"> 
                                                   <h4>{item.title}</h4>
                                                   <p>{item.seriesName}</p> 
                                                   <div className="works-opertion">
                                                      <span><img src="image/icon/read-icon.svg" alt=""></img>{item.clickNumber}</span>
                                                      <span><img src="image/icon/zan-icon.svg" alt=""></img>{item.likeNumber}</span>
                                                   </div> 
                                                   <div className="works-bottom">
                                                      <span className="works-name">{item.creator.name}</span>
                                                      <span className="works-time">{moment(item.time).format('YYYY-MM-DD')}</span>
                                                   </div>                                             
                                                </div>
                                            </div>                                       
                                       </li>
                                       ))
                                   }                                 
                               </ul> :null                               
                            }
                            {
                                isData ? <div className="noData" style={{padding:'100px 0'}}>
                                    <img src="image/no-data.png" alt=""></img>
                                    <p>没有找到符合条件的结果</p>
                                </div> :null
                            }
                            </div>
                            {/* 分页 */}
                            {                            
                            storyPageCount > 1  ?                         
                            <div className="pagebox">
                                 <span className="pageleft">
                                    <span className="pagefirst" 
                                        style={{color:(page === 1 ? '#b2b2b2' : ''),cursor:(page === 1 ? 'default' : 'pointer')}} 
                                        onClick={(e)=>this.handleClickPageAjax(e,'first',limit,page,storyPageCount,'page')}>首页</span>
                                    <span  className="pageprev" 
                                        style={{color:(page === 1 ? '#b2b2b2' : ''),cursor:(page === 1 ? 'default' : 'pointer')}} 
                                        onClick={(e)=>this.handleClickPageAjax(e,'prev',limit,page,storyPageCount,'page')}>上一页</span>
                                 </span>
                                 <span className="pagemid">
                                     当前第<input type="text" className="pageNumInput" 
                                     onChange={(e)=>this.handleChangePageAjax_1(e,storyPageCount,'page')} 
                                     onKeyUp={(e)=>this.handleSendPageAjax_1(e,limit)} value={page} />页，共<em>{storyPageCount}</em>页，<em>{storyTotalNumber}</em>条搜索结果
                                 </span>
                                 <span className="pageright">
                                    <span className="pagenext" 
                                        style={{color:(page === storyPageCount ? '#b2b2b2' : ''),cursor:(page === storyPageCount ? 'default' : 'pointer')}}
                                        onClick={(e)=>this.handleClickPageAjax(e,'next',limit,page,storyPageCount,'page')}>下一页</span>
                                    <span  className="pagelast" 
                                        style={{color:(page === storyPageCount ? '#b2b2b2' : ''),cursor:(page === storyPageCount ? 'default' : 'pointer')}}
                                        onClick={(e)=>this.handleClickPageAjax(e,'last',limit,page,storyPageCount,'page')}>末页</span>
                                 </span>
                            </div>:null
                            }                       
                       </div>
                    </div>                                       
                </div>  
                <Footer/>  
            </div>
        );
    };
    componentDidMount() {
        let that = this;
        const {seriesKey,starKey,page,limit} = this.state;
        const { getStoryInfo,getStoryAlbum} = this.props;
        getStoryInfo(starKey);  
        let param = {};
        param = {
           type:1,
           starKey:starKey,
           seriesKey:seriesKey,
           curPage:page,
           perPage: limit
        }      
        getStoryAlbum(param);     
    }
}
export default connect(
    mapStateToProps,
    { getStoryInfo,getStoryAlbum,getStoryDetail},
)(Works);
