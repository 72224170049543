import React, { Component } from 'react';
import Footer from '../../src/components/Footer';
import './Review.css';
class Review extends Component {
    render() {
        return (
            <div className="homepage review-box">               
               <div className="about-box">
                    <div className="container">
                        <div className="noData">
                            <img src="image/no-data.png" alt=""></img>
                            <p>没有找到符合条件的结果</p>
                        </div>
                    </div>
               </div>               
               <Footer />
            </div>
        );
    };
}

export default Review;